import { types as ActionTypes, asyncEvents as AsyncEvents } from './Reporting.actions';
import {
    errorReducer,
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '../../common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import moment from 'moment';
import reduceReducers from 'reduce-reducers';

export const defaultFilters = {
    // default date range is past 6 months
    dateRange: [
        moment().utc().subtract(6, 'months').startOf('day').valueOf(),
        moment().utc().endOf('day').valueOf(),
    ],
    forms: [],
    jurisdictions: [],
    requestTypes: [],
    // paging params are for action items by assignees table
    limit: 50,
};

export const initialState = {
    error: null,
    filters: { ...defaultFilters },
    loading: false,
};

export default reduceReducers(
    initialState,
    combineReducers({
        filters: passthroughReducer(initialState.filters),
        loading: loadingReducer(
            initialState.loading,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
        error: errorReducer(
            initialState.error,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    genericReducer(initialState, {
        [ActionTypes.setFilters]: (state, filters) => ({
            ...state,
            filters: {
                ...state.filters,
                ...filters,
            },
        }),
        [AuthActionTypes.logout]: () => initialState,
    })
);
