/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo } from 'react';
import reducer, { initState } from './DateRangePicker.reducer';

import Actions from './DateRangePicker.actions';
import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import DateRangePickerDay from './components/DateRangePickerDay';
import DateRangePickerInput from './components/DateRangePickerInput';
import DateRangePickerToolbar from './components/DateRangePickerToolbar';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useThunkReducer } from '../../helpers/Hooks';

/**
    @typedef DateRangePickerProps
    @type {Object}
    @property {string} format The formatter for the date text input
    @property {number} maxDate The initial value (in milliseconds) for the maximum date in the range
    @property {number} minDate The initial value (in milliseconds) for the minimum date in the range
    @property {number} maxRangeDays The maximum duration of the range in days (or 0 for infinite)
    @property {function} onChange The callback method for the picker
*/

/**
 * DateRangePicker Component
 * @param {DateRangePickerProps} props
 * @returns
 */
const DateRangePicker = props => {
    const {
        format,
        maxDate: providedMaxDate, // eslint-disable-line no-unused-vars
        minDate: providedMinDate, // eslint-disable-line no-unused-vars
        maxRangeDays: providedMaxRangeDays, // eslint-disable-line no-unused-vars
        onChange,
        startText,
        endText,
        toolbarFormat,
        title,
        startPlaceholder,
        endPlaceholder,
        useUTC,
        ...remain
    } = props;

    const [state, dispatch] = useThunkReducer(reducer, props, initState);
    const { picking, minDate, maxDate, open, maxRangeDays, selectedMinDate, selectedMaxDate } =
        state;

    const handleOpen = useCallback(() => dispatch(Actions.openDialog()), [dispatch]);
    const handlePick = useCallback(picking => dispatch(Actions.pick(picking)), [dispatch]);
    const handleClose = useCallback(() => dispatch(Actions.closeDialog()), [dispatch]);
    const handleAccept = useCallback(
        () => dispatch(Actions.accept([selectedMinDate, selectedMaxDate])),
        [dispatch, selectedMinDate, selectedMaxDate]
    );
    const handleChange = useCallback(date => dispatch(Actions.change(date)), [dispatch]);
    const handleSelectRange = useCallback(picked => dispatch(Actions.pick(picked)), [dispatch]);

    useEffect(() => {
        if (!open) {
            onChange && onChange([minDate, maxDate].filter(v => !!v));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, minDate, maxDate]);

    const clampedMaxDate = useMemo(
        () =>
            useUTC
                ? moment.utc(selectedMinDate).add(maxRangeDays, 'days')
                : moment(selectedMinDate).add(maxRangeDays, 'days'),
        [selectedMinDate, maxRangeDays, useUTC]
    );
    const clampedMinDate = useMemo(
        () =>
            useUTC
                ? moment.utc(selectedMaxDate).subtract(maxRangeDays, 'days')
                : moment(selectedMaxDate).subtract(maxRangeDays, 'days'),
        [selectedMaxDate, maxRangeDays, useUTC]
    );

    /**
     * @param {moment.Moment} date
     * @returns boolean
     */
    const shouldDisableDate = useCallback(
        /**
         * @param {moment.Moment} date
         * @returns boolean
         */
        date => {
            return picking === 'min'
                ? selectedMaxDate && date.isAfter(selectedMaxDate, 'day')
                : (selectedMinDate && date.isBefore(selectedMinDate, 'day')) ||
                      date.isAfter(new Date(), 'day');
        },
        [picking, selectedMaxDate, selectedMinDate]
    );

    const renderWrappedDay = useCallback(
        (date, selectedDates, pickersDayProps) => {
            const { disabled: isDisabled } = pickersDayProps;
            const disabled = isDisabled || shouldDisableDate(date);
            const selected =
                date.isSame(selectedMinDate, 'day') || date.isSame(selectedMaxDate, 'day');
            const overrideProps = {
                day: date,
                disabled,
                // Previewing is the dotted outline around valid selectable dates
                selected,
                isPreviewing:
                    !disabled &&
                    !!picking &&
                    (picking === 'min'
                        ? date.isSameOrAfter(clampedMinDate, 'day') &&
                          date.isSameOrBefore(selectedMinDate, 'day')
                        : date.isSameOrBefore(clampedMaxDate, 'day') &&
                          date.isSameOrAfter(selectedMaxDate, 'day')),
                isStartOfPreviewing:
                    !!picking &&
                    (picking === 'min'
                        ? date.isSame(clampedMinDate, 'day')
                        : date.isSame(selectedMaxDate, 'day')),
                isEndOfPreviewing:
                    !!picking &&
                    (picking === 'max'
                        ? date.isSame(clampedMaxDate, 'day') ||
                          shouldDisableDate(date.clone().add(1, 'day'))
                        : date.isSame(selectedMinDate, 'day')),
                // Selected is the solid outline around the currently selected dates
                isHighlighting: date.isBetween(selectedMinDate, selectedMaxDate, 'day', '[]'),
                isStartOfHighlighting: date.isSame(selectedMinDate, 'day'),
                isEndOfHighlighting: date.isSame(selectedMaxDate, 'day'),
            };
            return (
                <DateRangePickerDay
                    key={date.format('DD-MM-YYYY')}
                    {...pickersDayProps}
                    {...overrideProps}
                />
            );
        },
        [
            clampedMaxDate,
            clampedMinDate,
            picking,
            selectedMinDate,
            selectedMaxDate,
            shouldDisableDate,
        ]
    );

    const renderInput = useCallback(
        ({ inputProps, InputProps }) => {
            const startProps = {
                inputProps,
                InputProps,
                label: startText,
                value: minDate,
                placeholder: startPlaceholder,
            };
            const endProps = {
                inputProps,
                InputProps,
                label: endText,
                value: maxDate,
                placeholder: endPlaceholder,
            };
            return (
                <DateRangePickerInput
                    format={format}
                    onPick={handlePick}
                    startProps={startProps}
                    endProps={endProps}
                    title={title}
                    useUTC={useUTC}
                />
            );
        },
        [format, handlePick, startText, endText, minDate, maxDate, useUTC]
    );

    const renderToolbar = useCallback(
        props => (
            <DateRangePickerToolbar
                {...props}
                format={toolbarFormat || format}
                key="DatePickerToolbar"
                onSelectRange={handleSelectRange}
                picking={picking}
                maxDate={selectedMaxDate}
                minDate={selectedMinDate}
                useUTC={useUTC}
            />
        ),
        [format, selectedMaxDate, selectedMinDate, picking, handleSelectRange, useUTC]
    );

    return (
        <DatePicker
            clearable
            allowSameDateSelection
            {...remain}
            shouldDisableDate={shouldDisableDate}
            disableFuture
            value={
                useUTC
                    ? moment.utc(picking === 'min' ? selectedMinDate : selectedMaxDate)
                    : moment(picking === 'min' ? selectedMinDate : selectedMaxDate)
            }
            minDate={
                picking !== 'min'
                    ? useUTC
                        ? moment.utc(selectedMinDate)
                        : moment(selectedMinDate)
                    : undefined
            }
            maxDate={
                picking !== 'max'
                    ? useUTC
                        ? moment.utc(selectedMaxDate)
                        : moment(selectedMaxDate)
                    : undefined
            }
            renderDay={renderWrappedDay}
            format={format}
            onAccept={handleAccept}
            onChange={handleChange}
            inputFormat={format}
            renderInput={renderInput}
            ToolbarComponent={renderToolbar}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
        />
    );
};

DateRangePicker.propTypes = {
    format: PropTypes.string,
    startText: PropTypes.string,
    endText: PropTypes.string,
    maxDate: PropTypes.number,
    minDate: PropTypes.number,
    maxRangeDays: PropTypes.number,
    onChange: PropTypes.func,
    toolbarFormat: PropTypes.string,
    startPlaceholder: PropTypes.string,
    endPlaceholder: PropTypes.string,
    useUTC: PropTypes.bool,
};

export default DateRangePicker;
