import { Button, Toolbar, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import styles from './DateRangePickerToolbar.styles';

const useStyles = makeStyles(styles);

const DateRangePickerToolbar = props => {
    const { format = 'MMM D, YYYY', picking, onSelectRange, minDate, maxDate, useUTC } = props;
    const classes = useStyles(props);

    const min = minDate
        ? useUTC
            ? moment.utc(minDate).format(format)
            : moment(minDate).format(format)
        : 'Pick Start Date';
    const max = maxDate
        ? useUTC
            ? moment.utc(maxDate).format(format)
            : moment(maxDate).format(format)
        : 'Pick End Date';
    const minSelected = picking === 'min';
    const maxSelected = picking === 'max';

    const handleMinClick = () => {
        onSelectRange('min');
    };

    const handleMaxClick = () => {
        onSelectRange('max');
    };

    return (
        <Toolbar classes={classes}>
            <div className={classes.container}>
                <Button
                    variant="text"
                    onClick={handleMinClick}
                    classes={{
                        root: minSelected ? classes.buttonSelected : classes.button,
                    }}
                    size="large"
                >
                    {min}
                </Button>
                <Typography classes={{ root: classes.button }}>{' – '}</Typography>
                <Button
                    variant="text"
                    onClick={handleMaxClick}
                    classes={{
                        root: maxSelected ? classes.buttonSelected : classes.button,
                    }}
                    size="large"
                >
                    {max}
                </Button>
            </div>
        </Toolbar>
    );
};

DateRangePickerToolbar.propTypes = {
    format: PropTypes.string,
    onSelectRange: PropTypes.func,
    picking: PropTypes.string,
    maxDate: PropTypes.number,
    minDate: PropTypes.number,
    useUTC: PropTypes.bool,
};

export default DateRangePickerToolbar;
