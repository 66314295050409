import { useRef } from 'react';

/**
 * @template R
 * @typedef {(...args: A) => R} BoundFunction
 */

/**
 * @function bind
 * @memberof Function.prototype
 * @param {object} thisArg The object to be bound as 'this'
 * @param {...*} args Arguments to be prepended to the function call
 * @returns {BoundFunction} A new function with the bound 'this' and prepended arguments
 */

/**
 * Binds the function `method` to the passed `props` as arguments. Uses
 * `useRef` to store references to both `props` and `method`. `method` will
 * receive each of the `props` as arguments in the same order they are passed
 * and then each argument passed to the returned function.
 *
 * Conceptually similar to [Function.prototype.bind]{@https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Function/bind}.
 * @example
 * const boundCallback = useBoundCallback((prop1, prop2, arg1, arg2) => {
 *     console.log(prop1, prop2, arg1, arg2);
 * }, ['myProp1', 'myProp2']);
 * boundCallback('my first arg', 'my second arg');
 * // result: "myProp1 myProp2 my first arg my second arg"
 * @example
 * const myService = (extraParam, event) => {
 *    event.preventDefault();
 *    console.log(extraParam); // "myExtraParam"
 * };
 * ...
 * const onSubmit = useBoundCallback(myService, ['myExtraParam']);
 * ...
 * <form onSubmit={onSubmit} />
 * @template T
 * @param {(...args: any[]) => T} method
 * @param {any[]} [props=[]]
 * @returns {(...args: any[]) => T}
 */
export function useBoundCallback(method, props = []) {
    const previousProps = useRef(props);
    const boundMethod = useRef((...args) => method(...previousProps.current.concat(args)));

    previousProps.current = props;

    return boundMethod.current;
}

export default useBoundCallback;
