/* eslint-disable camelcase */
import {
    CMP_CONSENT_CATEGORIES,
    CMP_CONSENT_PLATFORMS,
    PUBLISH_STATUS,
    validConsentConfigModes,
} from './constants';
import { formatDate, makeResultsFormatter } from '../common/services/helpers';

import castArray from 'lodash/castArray';
import { formatOrganizations } from '../organizations/format';
import { htmlDecode } from '../common/helpers/String';
import identity from 'lodash/identity';
import palette from '../common/theme/palette';
import { parseColor } from '../common/helpers/Color';

export const assignConsentConfig = ccId => details => ({
    ...details,
    consentConfig: ccId,
});

const interpretPublishStatus = ({ publishStatus, updated, lastPublished, mode }) => {
    if (publishStatus === null) {
        return PUBLISH_STATUS.UNPUBLISHED;
    }

    if (publishStatus === 'complete') {
        if (updated === lastPublished) {
            return PUBLISH_STATUS.READY;
        }

        if (updated > lastPublished) {
            return PUBLISH_STATUS.UPDATED;
        }
        const productionMode = mode === 'production';
        const permissiveMode = mode === 'permissive';
        return productionMode || permissiveMode ? PUBLISH_STATUS.READY : PUBLISH_STATUS.MODE_CHANGE;
    }

    return publishStatus;
};

export const formatConfig = details => {
    const {
        domains,
        name,
        mode,
        publishedRevision,
        configuration: {
            fakeIt,
            additionalLinks = [],
            storagePolicyHref = '',
            iab: originalIab = {},
            ...configuration
        } = {},
        orgIds = [],
        organizations = orgIds,
        ...remain
    } = details;

    // CMP-1607: rename iab.eu to iab.tcf
    const { eu: tcf, ...iabRest } = originalIab;
    const iab = tcf
        ? {
              ...iabRest,
              tcf,
          }
        : originalIab;

    return {
        ...remain,
        name: htmlDecode(name), // React automatically encodes HTML under the hood, so we need to provide a decoded string
        iabVendors: Object.values(iab.tcf?.v2?.vendors || {}),
        publishedRevision,
        configuration: {
            ...configuration,
            iab,
            storagePolicyHref: decodeURI(storagePolicyHref),
            additionalLinks: additionalLinks.map(([text, href]) => [text, decodeURI(href)]),
        },
        publishStatus: interpretPublishStatus(details),
        mode:
            fakeIt && mode === 'production'
                ? 'permissive'
                : validConsentConfigModes.includes(mode)
                  ? mode
                  : validConsentConfigModes[0],
        domains: castArray(domains),
        organizations: formatOrganizations(organizations),
    };
};
export const formatConfigResults = makeResultsFormatter(formatConfig);

export const formatScanUrl = data => {
    const now = new Date().getTime();
    const newDate = new Date();

    const {
        customerId,
        configId,
        url,
        created = now,
        updated = now,
        lastScan = null,
        nextScan = null,
        ...remain
    } = data;
    let lastScanDate = formatDate(lastScan);

    let nextScanDate;
    if (!lastScan) {
        nextScanDate = newDate.setMonth(newDate.getMonth() + 1);
    } else {
        nextScanDate = nextScan;
    }

    const id = `${customerId}-${configId}_${url}`;
    return {
        customerId,
        configId,
        url,
        scanningActive: true,
        scanStatus: null,
        lastScanReason: null,
        id,
        nextScan: nextScanDate,
        lastScan: lastScanDate,
        created,
        updated,
        ...remain,
    };
};
export const formatScanUrlResults = makeResultsFormatter(formatScanUrl);

export const formatSitemapURL = data => {
    const now = new Date().getTime();
    const newDate = new Date();
    const {
        customerId,
        configId,
        url,
        created = now,
        updated = now,
        lastScan = null,
        nextScan = null,
        ...remain
    } = data;
    let lastScanDate = formatDate(lastScan);
    const id = `${customerId}-${configId}_${url}`;

    let nextScanDate;
    if (!lastScan) {
        nextScanDate = newDate.setMonth(newDate.getMonth() + 1);
    } else {
        nextScanDate = nextScan;
    }

    return {
        customerId,
        configId,
        url,
        scanningActive: true,
        lastScanStatus: null,
        lastScanReason: null,
        lastScanError: null,
        id,
        nextScan: nextScanDate,
        lastScan: lastScanDate,
        created,
        updated,
        ...remain,
    };
};

export const formatSitemapURLResults = makeResultsFormatter(formatSitemapURL);

export const formatTattleResults = response => {
    const data = {
        ...response,
        results: response.results.map(tattle => ({
            ...tattle,
            ...(tattle.storeType === 'cookie' && {
                disclosure: !!tattle.disclosure,
            }),
            classification: tattle.classification || 'UNKNOWN',
            pattern: tattle.pattern || tattle.storeKey,
        })),
    };
    return makeResultsFormatter(identity)(data);
};

export const formatRuleResults = makeResultsFormatter(identity);

export const formatConsentData = data => {
    const {
        osnoCustomerId: customerId,
        osnoConfigId: ccId,
        userConsentId,
        consented: consentedCategories, // ["Marketing", "Analytics", "Personalization", "Essential"]
        consentTime,
        usrIp: ip,
        usrLang: lang,
        usrCountry: country,
    } = data;
    return {
        customer: customerId,
        ccId,
        userConsentId,
        consentedCategories,
        // Convert to milliseconds
        consentTime: consentTime * 1000,
        ip,
        lang,
        country,
    };
};
export const formatConsentDataResults = makeResultsFormatter(formatConsentData);

/*
{
    labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug'],
    datasets: [
        {
            label: 'ConsentConfig',
            backgroundColor: palette.primary.main,
            data: [18000, 5000, 19000, 27000, 29000, 19000, 20000],
        },
        {
            label: 'Declines',
            backgroundColor: palette.common.neutral,
            data: [11000, 20000, 12000, 29000, 30000, 25000, 13000],
        },
    ],
}
*/

export const formatConsentStats = data => {
    const consentEntities = Object.entries(data);

    const essentialColor = parseColor(palette.primary.main).slice(0, 3);
    const analyticsColor = parseColor(palette.data1.main).slice(0, 3);
    const marketingColor = parseColor(palette.data2.main).slice(0, 3);
    const personalizationColor = parseColor(palette.data3.main).slice(0, 3);

    const graph = {
        labels: consentEntities.map(([timestamp]) => timestamp),
        datasets: [
            {
                label: 'layouts.Dashboard.components.ConsentGraph.datasets.essential.label',
                backgroundColor: `rgba(${essentialColor.join(',')}, 0.4)`,
                borderColor: palette.primary.main,
                fill: 'origin',
                data: consentEntities.map(([, { Essential }]) => Essential),
            },
            {
                label: 'layouts.Dashboard.components.ConsentGraph.datasets.analytics.label',
                backgroundColor: `rgba(${analyticsColor.join(',')}, 0.4)`,
                borderColor: palette.data1.main,
                fill: '-3',
                data: consentEntities.map(([, { Analytics }]) => Analytics),
            },
            {
                label: 'layouts.Dashboard.components.ConsentGraph.datasets.marketing.label',
                backgroundColor: `rgba(${marketingColor.join(',')}, 0.4)`,
                borderColor: palette.data2.main,
                fill: '-1',
                data: consentEntities.map(([, { Marketing }]) => Marketing),
            },
            {
                label: 'layouts.Dashboard.components.ConsentGraph.datasets.personalization.label',
                backgroundColor: `rgba(${personalizationColor.join(',')}, 0.4)`,
                borderColor: palette.data3.main,
                fill: '-2',
                data: consentEntities.map(([, { Personalization }]) => Personalization),
            },
        ],
    };

    return graph;
};

export const formatConsentTrafficStats = data => {
    const consentEntities = Object.entries(data);

    const trafficColor = parseColor(palette.primary.main).slice(0, 3);

    const graph = {
        labels: consentEntities.map(([timestamp]) => timestamp),
        datasets: [
            {
                label: 'layouts.Dashboard.components.TrafficGraph.datasets.traffic.label',
                backgroundColor: `rgba(${trafficColor.join(',')}, 0.4)`,
                borderColor: palette.primary.main,
                fill: 'origin',
                data: consentEntities.map(([, { count }]) => count),
            },
        ],
    };

    return graph;
};

/**
 * @template {string} C
 * @template {string} R
 * @param {C} divisionOrCountryCode
 * @param {R} [regionCode]
 * @returns {`` extends `${R}` ? SnakeToKebabCase<Lowercase<C>> : Lowercase<`${SnakeToKebabCase<C>}-${R}`>}
 */
export const toDivisionCode = (divisionOrCountryCode, regionCode = undefined) =>
    `${divisionOrCountryCode.split('_').join('-')}${typeof regionCode === 'string' && regionCode ? `-${regionCode}` : ''}`.toLowerCase();

export const formatConsentReports = (/** @type {CmpReportsResponseData} */ data) => {
    const errors = {};
    const {
        classificationByConfig,
        consentsByJurisdiction,
        consentsByPlatform,
        consentsOverTime,
        stats,
        traffic,
    } = Object.entries(data).reduce((acc, [key, { message, response }]) => {
        if (message === 'success') {
            acc[`${key}`] = response;
            errors[`${key}`] = null;
            return acc;
        }
        errors[`${key}`] = message;
        return acc;
    }, {});

    const [minDatePlatform, maxDatePlatform, minPlatformConsents, maxPlatformConsents] = (
        consentsByPlatform || []
    ).reduce(
        (acc, row) => {
            const date = new Date(row.date).getTime();
            return [
                Math.min(acc[0], date),
                Math.max(acc[1], date),
                Math.min(acc[2], row.totalConsents),
                Math.max(acc[3], row.totalConsents),
            ];
        },
        [Number.MAX_SAFE_INTEGER, 0, Number.MAX_SAFE_INTEGER, 0]
    );
    const [minDate, maxDate, minTimeConsents, maxTimeConsents] = (consentsOverTime || []).reduce(
        (acc, row) => {
            const date = new Date(row.date).getTime();
            return [
                Math.min(acc[0], date),
                Math.max(acc[1], date),
                Math.min(acc[2], row.totalConsents),
                Math.max(acc[3], row.totalConsents),
            ];
        },
        [minDatePlatform, maxDatePlatform, Number.MAX_SAFE_INTEGER, 0]
    );

    return {
        errors,
        dateRange: [minDate, maxDate],
        platformConsentRange: [minPlatformConsents, maxPlatformConsents],
        timeConsentRange: [minTimeConsents, maxTimeConsents],
        consentRange: [
            Math.min(minPlatformConsents, minTimeConsents),
            Math.max(maxPlatformConsents, maxTimeConsents),
        ],
        totalRange: [
            Math.min(minPlatformConsents, minTimeConsents),
            Math.max(maxPlatformConsents, maxTimeConsents),
        ],
        stats,
        traffic,
        classificationByConfig,
        consentsByJurisdiction: consentsByJurisdiction?.map(row => ({
            ...row,
            ...Object.entries(row.consents).reduce(
                (acc, [key, value]) => {
                    if (!CMP_CONSENT_CATEGORIES[`${key}`]) {
                        if (process.env.NODE_ENV === 'development') {
                            // eslint-disable-next-line no-console
                            console.warn(`Unknown category: ${key}`);
                        }
                        return acc;
                    }
                    if (['STORAGE'].includes(key)) {
                        return acc;
                    }
                    acc[`${CMP_CONSENT_CATEGORIES[`${key}`]}TotalConsents`] = value;
                    return acc;
                },
                {
                    jurisdiction: toDivisionCode(row.countryCode, row.regionCode),
                    totalConsents: row.totalConsents,
                }
            ),
        })),
        consentsByPlatform: consentsByPlatform?.map(row => ({
            ...row,
            ...Object.entries(row.consents).reduce((acc, [key, value]) => {
                if (!CMP_CONSENT_PLATFORMS[`${key}`]) {
                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.warn(`Unknown platform: ${key}`);
                    }
                    return acc;
                }
                acc[`${CMP_CONSENT_PLATFORMS[`${key}`]}TotalConsents`] = value;
                return acc;
            }, {}),
        })),
        consentsByCategory: Object.entries(
            (consentsOverTime && consentsOverTime.length
                ? consentsOverTime
                : [{ totalConsents: 0 }]
            ).reduce((acc, row) => {
                Object.entries(
                    Object.keys(CMP_CONSENT_CATEGORIES).reduce((acc, key) => {
                        if (['ESSENTIAL', 'STORAGE'].includes(key)) {
                            return acc;
                        }
                        acc[`${key}`] = (row?.consents && row?.consents[`${key}`]) || 0;
                        return acc;
                    }, {})
                ).forEach(([key, optIn]) => {
                    if (['ESSENTIAL', 'STORAGE'].includes(key)) {
                        return;
                    }
                    const category = acc[`${key}`] || {
                        optIn: 0,
                        optOut: 0,
                    };
                    category.optIn += optIn;
                    category.optOut -= row.totalConsents - optIn; // Make the value negative so it shows up on the left side of the chart
                    acc[`${key}`] = category;
                });
                return acc;
            }, {})
        ).map(([category, { optIn, optOut }]) => ({
            category,
            optIn,
            optOut,
        })),
        consentsOverTime: consentsOverTime?.map(row => ({
            ...row,
            ...Object.entries(row.consents).reduce((acc, [category, optIn]) => {
                if (category === 'ESSENTIAL') {
                    return acc;
                }
                acc[`${CMP_CONSENT_CATEGORIES[`${category}`]}OptIn`] = optIn;
                acc[`${CMP_CONSENT_CATEGORIES[`${category}`]}OptOut`] = row.totalConsents - optIn;
                return acc;
            }, {}),
        })),
    };
};

export const formatConfigRevisions = (data, idx) => {
    const { revisionNumber, created, customerId, description } = data;
    let createdDate = formatDate(created);

    return {
        revisionNumber,
        description,
        created: createdDate,
        customerId,
        latestRevision: idx === 0,
    };
};

export const formatConfigRevisionsResults = makeResultsFormatter(formatConfigRevisions);
