import { jurisdictionLookup } from './Address';

// jurisdictions are sourced from /b2b/assets/images/flags in webpack.common.config.js
// based on flag icons available there, which informs the dropdown options in the UI

let jurisdictions;

try {
    jurisdictions = Array.isArray(process.env.SUPPORTED_JURISDICTIONS)
        ? process.env.SUPPORTED_JURISDICTIONS
        : JSON.parse(process.env.SUPPORTED_JURISDICTIONS);
} catch (e) {
    jurisdictions = ['us', 'ca', 'uk', 'au', 'nz', 'eu', 'br', 'mx', 'in', 'za', 'hk', 'sg'];
}

/** @type {ReturnType<jurisdictionLookup>[]} */
export const SUPPORTED_JURISDICTIONS = jurisdictions.map(jurisdictionLookup).filter(Boolean);
export const jurisdictionOptions = SUPPORTED_JURISDICTIONS.map(jurisdiction => ({
    value: jurisdiction.code.toLowerCase().split('_').join('-'),
    label: jurisdiction.name,
    country: jurisdiction.country.toLowerCase(),
    territory: jurisdiction.territory.toLowerCase(),
})).sort((a, b) => a.value.localeCompare(b.value));

/** @type {Record<string,{ value: string, label: string, country: string, territory: string }>} */
export const jurisdictionLookupMap = jurisdictionOptions.reduce((acc, jurisdiction) => {
    acc[jurisdiction.value] = jurisdiction;
    return acc;
}, {});

export const getLabelForJurisdictionOption = option => {
    const { value, label, country } = option || {};
    let countryName = '';
    if (value && value !== country) {
        countryName = `${jurisdictionLookupMap[country]?.label || country || ''} - `;
    }
    return `${countryName}${jurisdictionLookupMap[value]?.label || label || value || ''}`;
};

export const jurisdictionCodes = Object.keys(jurisdictionLookupMap);
