import {
    Chip,
    CircularProgress,
    LinearProgress,
    Autocomplete as MuiAutocomplete,
} from '@mui/material';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import Locale from '../Locale';
import PropTypes from 'prop-types';
import TextField from './TextField';
import castArray from 'lodash/castArray';
import debounce from 'lodash/debounce';
import escapeRegExp from 'lodash/escapeRegExp';
import isEqual from 'lodash/isEqual';
import { translate } from '../../helpers/i18n';
import useBoundCallback from '../../helpers/Hooks/useBoundCallback';
import { withFormikField } from './FormikFields';

const emptyArray = [];
const LOADING_MORE_OPTIONS = { label: '...', value: 'loading' };

const defaultFilterOptions = (options, { inputValue }) => {
    if (!inputValue) {
        // No search string, return everything
        return options;
    }
    // eslint-disable-next-line security/detect-non-literal-regexp
    const regExp = new RegExp(
        `(${escapeRegExp(inputValue).split(' ').filter(Boolean).join(')|(')})`,
        'im'
    );
    return options.filter(option => {
        const { label = option } = option;
        return regExp.test(`${label}`);
    });
};

export const defaultGetOptionLabel = option =>
    option && typeof option === 'object'
        ? option?.label?.type === Locale
            ? translate(option.label.props.path)
            : option?.label || option?.value || ''
        : option || '';

export const defaultIsOptionEqualToValue = (option, value) =>
    !!castArray(value).find(
        toFind =>
            // Exact match is okay
            toFind === option ||
            (typeof option === 'string' || typeof option === 'number'
                ? // If option is a string, it must match the value of `toFind`
                  option === (toFind?.value || toFind)
                : // Value could be a primitive
                  typeof toFind === 'string' || typeof toFind === 'number'
                  ? isEqual(option?.value, toFind)
                  : // Otherwise, both the label and value must match
                    isEqual([toFind?.label, toFind?.value], [option?.label, option?.value]))
    );

const shouldLoadMore = target =>
    !!target && target.scrollHeight - target.scrollTop - target.clientHeight <= 1;

// There is a bug in MUI v5 where the ref provided in the ListboxProps will overwrite the only internally used by MUI
// This is a workaround to ensure we can access the internal ref
export const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, scrollRef, ...other } = props;
    const refFn = useCallback(node => {
        if (typeof ref === 'function') {
            ref(node);
        } else if (ref) {
            ref.current = node;
        }
        if (scrollRef && node) {
            scrollRef.current = node;
        }
    }, []);
    return (
        <ul {...other} ref={refFn}>
            {children}
        </ul>
    );
});

ListboxComponent.displayName = 'ListboxComponent';
ListboxComponent.propTypes = {
    children: PropTypes.node,
    scrollRef: PropTypes.object,
};

/**
 * @export
 * @typedef AutocompleteValue
 * @property {string} label
 * @property {string} value
 */

/**
 * @typedef {Object} Base
 * @property {number} minSearchCharacters
 * @property {boolean=} multiple
 * @property {number[]} newChipKeyCodes Key codes that will trigger the creation of a new option when multiple is true
 * @property {({ query: string, offset: number, limit: number }) => Promise<AutocompleteValue[]>} onRequestResults Async method that resolves an array of result objects in the form { label: string, value: string }
 * @property {number=} limit The maximum number of results to fetch at a time
 * @property {boolean=} paginate Allow results to infinitely scroll until there are no more
 * @property {Object=} translationOptions The `options` object provided to all internal <Locale/> translations for the TextField
 *
 * @typedef {Base & import('@mui/material').AutocompleteProps & Pick<import('@mui/material').TextFieldProps, 'InputProps'>} AutocompleteProps
 */

/**
 * @type {React.ForwardRefExoticComponent<AutocompleteProps>}
 */
const Autocomplete = React.forwardRef((props, ref) => {
    const {
        autoSelect,
        blurOnSelect,
        ChipProps,
        clearOnBlur,
        componentsProps = {},
        defaultValue,
        disableCloseOnSelect,
        error,
        filterOptions,
        filterSelectedOptions,
        freeSolo,
        getOptionLabel,
        groupBy,
        helperText,
        InputLabelProps,
        InputProps,
        inputProps,
        isOptionEqualToValue,
        minSearchCharacters = 1,
        multiple,
        name,
        newChipKeyCodes,
        limit,
        limitTags,
        ListboxProps,
        onChange,
        onClose,
        onInputChange,
        onKeyDown,
        onOpen,
        onRequestResults,
        open,
        options,
        paginate,
        renderGroup,
        renderInput,
        renderOption,
        renderTags,
        selectOnFocus,
        setFieldTouched,
        sx,
        translationOptions,
        ...remain
    } = props;

    const [loading, setLoading] = useState(false);
    const [_options, setOptions] = useState(options || emptyArray);
    const [scroll, setScroll] = useState(0);
    const [_open, setOpen] = useState(open ?? false);
    const [hasNextPage, setHasNextPage] = useState(paginate && !!limit && !!onRequestResults);
    const [inputValue, setInputValue] = useState('');
    const fetchResults = useRef(null);
    const listElem = useRef(null);

    // This is a workaround for the fact that the `getOptionLabel` prop is sometimes called with the option `value` by `useAutocomplete`
    const _getOptionLabel = useBoundCallback(
        (_options, getOptionLabel, isOptionEqualToValue, optionOrValue) => {
            const { label = optionOrValue } = optionOrValue || {};
            if (typeof label === 'string') {
                return label;
            }
            const option = _options.find(opt =>
                isOptionEqualToValue
                    ? isOptionEqualToValue(opt, optionOrValue)
                    : defaultIsOptionEqualToValue(opt, optionOrValue)
            );
            return getOptionLabel ? getOptionLabel(option) : defaultGetOptionLabel(option);
        },
        [_options, getOptionLabel, isOptionEqualToValue]
    );

    const getLabelForValue = useBoundCallback(
        (_getOptionLabel, isOptionEqualToValue, _options, value) => {
            const option = _options
                ? _options.find(option => {
                      return isOptionEqualToValue(option, value) || value === option?.label;
                  })
                : null;
            return option?.label || option?.value || value;
        },
        [_getOptionLabel, isOptionEqualToValue, _options]
    );

    const handleChange = useBoundCallback(
        (change, multiple, shouldBlurOnSelect, setFieldTouched, event, selected, reason) => {
            switch (reason) {
                case 'selectOption': {
                    if (shouldBlurOnSelect) {
                        /**
                         * Since Formik will not update the value in time for validation
                         * if the field is blurred on select, we delay the blur until
                         * the next tick.
                         */
                        const {
                            nativeEvent: { pointerType = true },
                        } = event;
                        if (shouldBlurOnSelect === true || shouldBlurOnSelect === pointerType) {
                            setTimeout(() => {
                                document.activeElement.blur();
                            }, 0);
                        }
                    }

                    // Fall through
                }
                case 'blur':
                // Only triggers when `autoSelect` is true

                // eslint-disable-next-line no-fallthrough
                case 'createOption': {
                    setInputValue(multiple ? '' : selected?.label || selected?.value || selected);
                    change && change(event, selected || null, reason);

                    if (reason === 'blur' && selected) {
                        /**
                         * Formik does not update the value in time for validation when the
                         * field is blurred, so we manually touch the field on next tick.
                         */
                        setTimeout(() => {
                            setFieldTouched?.(event.target.name);
                        }, 0);
                    }

                    break;
                }
                case 'removeOption': {
                    change && change(event, selected || null, reason);
                    break;
                }
                case 'clear': {
                    change && change(event, multiple ? [] : '', reason);
                    setInputValue('');
                    break;
                }
            }
        },
        [onChange, multiple, blurOnSelect, setFieldTouched]
    );

    const _filterOptions = useBoundCallback(
        (filterOptions, preventFilter, isLoading, options, state) => {
            // Allow for an autocomplete with async options to use a custom filter
            if (filterOptions && preventFilter) {
                return filterOptions(options, state);
            }

            return preventFilter
                ? // If you are async searching for a options, don't filter the options
                  options.length > 0 && isLoading
                    ? // If you are loading more options, add a loading indicator to the list at the end
                      [...options, LOADING_MORE_OPTIONS]
                    : options
                : filterOptions
                  ? filterOptions(options, state)
                  : defaultFilterOptions(options, state);
        },
        [filterOptions, !!onRequestResults, loading]
    );

    const handleInputChange = useBoundCallback(
        (onInputChange, currentInputValue, getLabelForValue, event, newInputValue) => {
            let modifiedInputValue = getLabelForValue(newInputValue);
            if (!event) {
                // The absence of an event means the input value was set programmatically by Formik
                setInputValue(`${modifiedInputValue}`);
                return;
            }
            try {
                modifiedInputValue = onInputChange
                    ? onInputChange(event, newInputValue) ?? newInputValue
                    : modifiedInputValue;
            } catch (e) {
                modifiedInputValue = false;
            }
            if (modifiedInputValue === false) {
                setInputValue(currentInputValue);
            } else {
                setInputValue(`${modifiedInputValue}`);
            }
        },
        [onInputChange, inputValue, getLabelForValue]
    );

    const handleKeyDown = useBoundCallback(
        (onKeyDown, multiple, newChipKeyCodes, event) => {
            // Allow the user to provide custom key codes to trigger the creation of a new option
            if (multiple && newChipKeyCodes?.includes(event.keyCode)) {
                // Let the Material UI component handle the creation of the new option
                event.key = 'Enter';
                event.keyCode = 13;
            }

            return onKeyDown && onKeyDown(event);
        },
        [onKeyDown, multiple, newChipKeyCodes]
    );

    const handleRequestResults = useBoundCallback(
        (onRequestResults, limit, _options, hasNextPage, searchTerm, offset = 0) => {
            setLoading(true);
            return onRequestResults
                ? onRequestResults({
                      query: searchTerm || '',
                      offset,
                      limit,
                  }).then(results => {
                      const newOptions = [...(offset ? _options : []), ...(results || [])];
                      if (fetchResults.current) {
                          setOptions(newOptions);
                          setLoading(false);
                          if (results.length < limit) {
                              setHasNextPage(false);
                          } else if (hasNextPage && shouldLoadMore(listElem.current)) {
                              fetchResults.current(searchTerm, newOptions.length);
                          }
                      }
                      return newOptions;
                  })
                : Promise.resolve().then(() => {
                      fetchResults.current && setLoading(false);
                      return _options;
                  });
        },
        [onRequestResults, limit, _options, hasNextPage]
    );

    const handleResultsScroll = useBoundCallback(
        (onScroll, loading, hasNextPage, offset, searchTerm, event) => {
            onScroll && onScroll(event);
            const listboxNode = listElem.current;
            const scrollPosition = listboxNode.scrollTop + listboxNode.clientHeight;
            if (!loading && hasNextPage && shouldLoadMore(listboxNode)) {
                setScroll(scrollPosition);
                fetchResults.current && fetchResults.current(searchTerm, offset);
            }
        },
        [ListboxProps?.onScroll, loading, hasNextPage, _options.length, inputValue]
    );

    const handleOpen = useBoundCallback(
        (onOpen, event) => {
            setOpen(true);
            return onOpen && onOpen(event);
        },
        [onOpen]
    );

    const handleClose = useBoundCallback(
        (onClose, event) => {
            fetchResults.current && fetchResults.current.cancel();
            setOpen(false);
            setLoading(false);
            return onClose && onClose(event);
        },
        [onClose]
    );

    const _renderInput = useBoundCallback(
        (
            error,
            helperText,
            InputLabelProps,
            InputProps,
            inputProps,
            remain,
            renderInput,
            translationOptions,
            params
        ) => {
            const { value } = remain;
            const val = typeof value === 'object' ? value?.value : remain?.value;

            const mergedProps = {
                ...remain,
                ...params,
                InputProps: {
                    name,
                    ...params?.InputProps,
                    ...InputProps,
                    startAdornment: (
                        <React.Fragment>
                            {InputProps?.startAdornment}
                            {params?.InputProps?.startAdornment}
                        </React.Fragment>
                    ),
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {InputProps?.endAdornment ?? params?.InputProps?.endAdornment ?? null}
                        </React.Fragment>
                    ),
                },
                inputProps: {
                    ...params?.inputProps,
                    ...inputProps,
                },
                InputLabelProps: {
                    ...params?.InputLabelProps,
                    ...InputLabelProps,
                },
                error: !!error,
                helperText,
                translationOptions,
                value: val,
            };

            return renderInput ? renderInput(mergedProps) : <TextField {...mergedProps} />;
        },
        [
            error,
            helperText,
            InputLabelProps,
            InputProps,
            inputProps,
            remain,
            renderInput,
            translationOptions,
        ]
    );

    const _renderOption = useBoundCallback(
        (_getOptionLabel, renderOption, props, option, state) => {
            if (option === LOADING_MORE_OPTIONS) {
                return (
                    <li
                        key="loading"
                        style={{
                            bottom: 0,
                            overflow: 'visible',
                            position: 'sticky',
                            transform: 'translateY(8px)',
                            width: '100%',
                        }}
                    >
                        <LinearProgress />
                    </li>
                );
            }
            const { value = option } = option;
            return renderOption ? (
                renderOption(props, option, state)
            ) : (
                <li {...props} key={value}>
                    {_getOptionLabel(option)}
                </li>
            );
        },
        [_getOptionLabel, renderOption]
    );

    const _renderTags = useBoundCallback(
        (disabled, _getOptionLabel, renderTags, value, getTagProps) => {
            return renderTags
                ? renderTags(value, getTagProps)
                : castArray(value).map((option, index) => {
                      return (
                          <Chip
                              key={option.value || index}
                              variant="outlined"
                              label={_getOptionLabel(option)}
                              {...getTagProps({ index })}
                              disabled={disabled || option.disabled}
                              {...ChipProps}
                          />
                      );
                  });
        },
        [props.disabled, _getOptionLabel, renderTags]
    );

    useLayoutEffect(() => {
        setLoading(false);
        fetchResults.current = debounce(handleRequestResults, 300, {
            trailing: true,
        });
        return () => {
            fetchResults.current && fetchResults.current.cancel();
            fetchResults.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleRequestResults]);

    useEffect(() => {
        setHasNextPage(paginate && !!limit && !!onRequestResults);
        if (paginate) {
            if (inputValue !== '' && `${inputValue}`.length >= minSearchCharacters) {
                setScroll(0);
            }
            _open && setOptions(options || emptyArray);
        }
        if (inputValue !== '' && `${inputValue}`.length >= minSearchCharacters) {
            fetchResults.current && fetchResults.current(inputValue);
        } else if (minSearchCharacters === 0 && _open) {
            fetchResults.current && fetchResults.current('');
        }
    }, [paginate, limit, inputValue, minSearchCharacters, _open, options]);

    useLayoutEffect(() => {
        if (options) {
            setOptions(options);
        }
    }, [`${options}`]);

    useEffect(() => {
        if (scroll && listElem.current) {
            listElem.current.scrollTop = scroll - listElem.current.clientHeight;
        }
    }, [loading]);

    return (
        <MuiAutocomplete
            autoSelect={autoSelect}
            componentsProps={componentsProps}
            clearOnBlur={onRequestResults ? false : clearOnBlur}
            defaultValue={remain.value == null ? defaultValue : undefined}
            disableCloseOnSelect={disableCloseOnSelect}
            filterOptions={_filterOptions}
            filterSelectedOptions={filterSelectedOptions}
            freeSolo={!!freeSolo}
            /* Setting the `getOptionLabel` prop shouldn't be necessary as setting
               `renderOption` overrides it, but as of MUI 5.2.7 there is some code
               in Autocomplete that still calls it. Something about our usage
               causes a lot of warnings without this set. This issue may be related:
               https://github.com/mui-org/material-ui/issues/26492 */
            getOptionLabel={_getOptionLabel}
            groupBy={groupBy}
            inputValue={inputValue}
            isOptionEqualToValue={isOptionEqualToValue}
            limitTags={limitTags}
            loading={loading}
            multiple={!!multiple}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onOpen={handleOpen}
            onClose={handleClose}
            open={_open}
            openOnFocus={true}
            options={_options}
            ref={ref}
            renderTags={_renderTags}
            renderInput={_renderInput}
            renderOption={_renderOption}
            renderGroup={renderGroup}
            selectOnFocus={selectOnFocus ?? !freeSolo}
            ListboxComponent={props.ListboxComponent ?? ListboxComponent}
            ListboxProps={{
                ...ListboxProps,
                style: {
                    ...ListboxProps?.style,
                    overflowY: 'auto',
                },
                scrollRef: listElem,
                onScroll: handleResultsScroll,
            }}
            sx={sx}
            {...remain}
        />
    );
});

Autocomplete.propTypes = {
    autoSelect: PropTypes.bool,
    blurOnSelect: PropTypes.bool,
    ChipProps: PropTypes.object,
    clearOnBlur: PropTypes.bool,
    componentsProps: PropTypes.object,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    value: PropTypes.any,
                }),
            ])
        ),
    ]),
    disableCloseOnSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    filterOptions: PropTypes.func,
    filterSelectedOptions: PropTypes.bool,
    freeSolo: PropTypes.bool,
    getOptionLabel: PropTypes.func,
    groupBy: PropTypes.func,
    helperText: PropTypes.any,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    inputProps: PropTypes.object,
    isOptionEqualToValue: PropTypes.func,
    label: PropTypes.string,
    limit: PropTypes.number,
    limitTags: PropTypes.number,
    ListboxComponent: PropTypes.elementType,
    ListboxProps: PropTypes.object,
    minSearchCharacters: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    newChipKeyCodes: PropTypes.arrayOf(PropTypes.number),
    open: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onFocus: PropTypes.func,
    onInputChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onOpen: PropTypes.func,
    onRequestResults: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
        })
    ),
    paginate: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    renderGroup: PropTypes.func,
    renderInput: PropTypes.func,
    renderOption: PropTypes.func,
    renderTags: PropTypes.func,
    required: PropTypes.bool,
    selectOnFocus: PropTypes.bool,
    setFieldTouched: PropTypes.func,
    sx: PropTypes.object,
    translationOptions: PropTypes.object,
    value: PropTypes.any,
};

Autocomplete.defaultProps = {
    filterOptions: defaultFilterOptions,
    filterSelectedOptions: true,
    getOptionLabel: defaultGetOptionLabel,
    isOptionEqualToValue: defaultIsOptionEqualToValue,
    limit: 5,
};

export default Autocomplete;

export const FormikAutocomplete = withFormikField(Autocomplete, { type: 'autocomplete' });
FormikAutocomplete.displayName = 'FormikAutocomplete';
