import { getCountryCallingCode, parsePhoneNumberFromString } from 'libphonenumber-js/max';

import { countryCodeLookup } from './Address';

export const formatPhoneNumber = value => value.replace(/[^\d/+]/g, '');

export const validatePhone = (value, country = 'US') => {
    const countryCode = countryCodeLookup(country);
    const phoneNumber = parsePhoneNumberFromString(`${value}`, {
        defaultCountry: countryCode,
        defaultCallingCode: countryCode && getCountryCallingCode(countryCode),
    });
    // Check that the phone number has a country (not an 800 number) and it's valid
    return phoneNumber?.country && phoneNumber.isValid();
};
