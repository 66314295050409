import { Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import FeatureFlag, { ENABLE_BILLING_PORTAL, On } from '/b2b/common/components/FeatureFlag';

import APIKeyIcon from '@mui/icons-material/VpnKey';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AdminHelpers from './components/AdminHelpers';
import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import Locale from '/b2b/common/components/Locale';
import NavLinkListItem from '/b2b/common/components/NavLinkListItem';
import OrganizationsIcon from '@mui/icons-material/BusinessOutlined';
import { PERMISSIONS } from '/b2b/authentication/constants';
import PaymentIcon from '@mui/icons-material/Payment';
import Permission from '/b2b/common/components/Permission';
import PropTypes from 'prop-types';
import React from 'react';
import SignoutIcon from '@mui/icons-material/ExitToAppOutlined';
import UsersIcon from '@mui/icons-material/People';
import WebhookIcon from '@mui/icons-material/Webhook';
import { billingPortalUrl } from '@osano-b2b';
import classNames from 'classnames';
import { devMode } from '/b2b/authentication/state/Auth.selectors';
import makeStyles from '@mui/styles/makeStyles';
import styles from './UserDetails.styles';

const useStyles = makeStyles(styles);

const UserDetails = props => {
    const {
        className,
        hasPermissionToViewOrganizations,
        onSignOut,
        onOrganizationsClick,
        shouldRenderApiKeys,
        userEmail,
    } = props;
    const classes = useStyles(props);
    const rootClassName = classNames(classes.root, className);

    const handleOrganizationsClick = e => {
        e.preventDefault();
        return onOrganizationsClick();
    };

    return (
        <div className={rootClassName}>
            <div className={classes.content}>
                <List
                    component="div"
                    subheader={
                        devMode ? (
                            <ListSubheader className={classes.listSubheader}>
                                <AdminHelpers />
                            </ListSubheader>
                        ) : undefined
                    }
                >
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLinkListItem}
                        to={AuthenticatedPaths.ACCOUNT}
                        isActive={(match, location) =>
                            match
                                ? true
                                : location.pathname.indexOf(AuthenticatedPaths.ACCOUNT) === 0
                        }
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <AccountBoxOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={<Locale path={'common.account'} />}
                        />
                    </ListItem>
                    <Permission forRole toPerform="view" on={AuthenticatedPaths.USERS}>
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.USERS}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(`${AuthenticatedPaths.USERS}/`) ===
                                      0
                            }
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <UsersIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path={'common.users'} />}
                            />
                        </ListItem>
                    </Permission>
                    <Permission forRole toPerform="view" on={AuthenticatedPaths.ORGANIZATIONS}>
                        <ListItem
                            className={
                                hasPermissionToViewOrganizations
                                    ? classes.listItem
                                    : classes.disabled
                            }
                            onClick={e =>
                                hasPermissionToViewOrganizations
                                    ? onOrganizationsClick
                                    : handleOrganizationsClick(e)
                            }
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.ORGANIZATIONS}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <OrganizationsIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path="common.fields.organizations.label" />}
                            />
                        </ListItem>
                    </Permission>
                    <Permission
                        toPerform={{
                            [AuthenticatedPaths.API_KEYS]: { view: true },
                            [PERMISSIONS.DATA_MAPPING]: { read: true },
                        }}
                        shouldRender={shouldRenderApiKeys}
                    >
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.API_KEYS}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(AuthenticatedPaths.API_KEYS) === 0
                            }
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <APIKeyIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path="common.apiKeys" />}
                            />
                        </ListItem>
                    </Permission>

                    <Permission
                        toPerform={{
                            [AuthenticatedPaths.WEBHOOKS]: { view: true },
                            [PERMISSIONS.ADMIN]: { read: true },
                        }}
                    >
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.WEBHOOKS}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(AuthenticatedPaths.WEBHOOKS) === 0
                            }
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <WebhookIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path="common.webhooks" />}
                            />
                        </ListItem>
                    </Permission>
                    <FeatureFlag flag={ENABLE_BILLING_PORTAL}>
                        <On>
                            <Permission
                                toPerform={{
                                    [PERMISSIONS.ADMIN]: { read: true },
                                }}
                            >
                                <ListItem
                                    className={classes.listItem}
                                    component="a"
                                    target={'_blank'}
                                    href={`${billingPortalUrl}?prefilled_email=${encodeURIComponent(userEmail)}`}
                                >
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <PaymentIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ primary: classes.listItemText }}
                                        primary={<Locale path="common.billing" />}
                                    />
                                </ListItem>
                            </Permission>
                        </On>
                    </FeatureFlag>
                </List>
                <Divider className={classes.listDivider} />
                <List component="div">
                    <ListItem className={classes.listItem} onClick={onSignOut}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <SignoutIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={<Locale path="common.signOut" />}
                        />
                    </ListItem>
                </List>
            </div>
        </div>
    );
};

UserDetails.propTypes = {
    className: PropTypes.string,
    onSignOut: PropTypes.func,
    onOrganizationsClick: PropTypes.func,
    hasPermissionToViewOrganizations: PropTypes.bool,
    shouldRenderApiKeys: PropTypes.bool,
    userEmail: PropTypes.string,
};

UserDetails.defaultProps = {
    onSignOut: () => {},
};

export default UserDetails;
