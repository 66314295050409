import AskExpertIcon from '../common/icons/AskExpert';
import AssessmentIcon from '../common/icons/Assessments';
import ConsentIcon from '../common/icons/ConsentManagement';
import DSARIcon from '../common/icons/DSAR';
import DefaultIcon from '@mui/icons-material/NotificationsActive';
import DocumentIcon from '../common/icons/Documents';
import GDPRIcon from '../common/icons/GDPR';
import LegislationIcon from '../common/icons/Legislation';
import LitigationIcon from '../common/icons/Litigation';
import ProductIcon from '../common/icons/Products';
import ScoreIcon from '../common/icons/Monitor';
import VendorIcon from '../common/icons/Vendors';

export const RESOURCE_TYPE = {
    ASKANALYST: 'ASKANALYST',
    ASSESSMENT: 'ASSESSMENT',
    ASSESSMENT_ASSIGNMENT: 'ASSESSMENT_ASSIGNMENT',
    CONSENTMANAGER: 'CONSENTMANAGER',
    DISCO: 'DISCO',
    DSAR: 'DSAR',
    GDPR: 'GDPR',
    LEGISLATION: 'LEGISLATION',
    LITIGATION: 'LITIGATION',
    PRODUCT: 'PRODUCT',
    SITE_BREACH: 'SITE_BREACH',
    VENDOR: 'VENDOR',
};

export const UPDATE_FREQUENCY = {
    ALWAYS: 'ALWAYS',
    NEVER: 'NEVER',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

export const EVENT_TYPE = {
    ASKANALYST_RESPONSE: 'ASKANALYST_REPLY',
    ASSESSMENT_ASSIGNED: 'ASSESSMENT_ASSIGNED',
    ASSIGNED: 'ASSIGNED',
    // ASSESSMENT_CLOSED: 'ASSESSMENT_CLOSED',
    // ASSESSMENT_DUE: 'ASSESSMENT_DUE',
    // ASSIGNED_ASSESSMENT_COMPLETE: 'ASSIGNED_ASSESSMENT_COMPLETE',
    DOCUMENT_CHANGE: 'DOCUMENT_CHANGE',
    DSAR_NEW_REQUEST: 'DSAR_NEW_REQUEST',
    DSAR_NEW_REQUEST_SUMMRY: 'DSAR_NEW_REQUEST_SUMMRY',
    DSAR_REQUEST_NEW_PORTAL_MESSAGE: 'DSAR_REQUEST_NEW_PORTAL_MESSAGE',
    DSAR_REQUEST_DEADLINE_SUMMRY: 'DSAR_REQUEST_DEADLINE_SUMMRY',
    DSAR_DEADLINE_SUMMARY_V2: 'DSAR_DEADLINE_SUMMARY_V2',
    GDPR_REQUEST: 'GDPR_REQUEST',
    LEGISLATION_POST_DELETE: 'LEGISLATION_POST_DELETE',
    LEGISLATION_POST_UPDATE: 'LEGISLATION_POST_UPDATE',
    LITIGATION_UPDATE: 'LITIGATION_UPDATE',
    SCAN: 'SCAN',
    SCORE_UPDATE: 'SCORE_UPDATE',
    TATTLE: 'TATTLE',
    VENDOR_ASSIGNED: 'VENDOR_ASSIGNMENT',
    VENDOR_BREACH: 'VENDOR_BREACH',
    REVIEW_DATE: 'REVIEW_DATE',
};

export const EVENT_RESOURCE_MAP = {
    ASKANALYST_RESPONSE: ['ASKANALYST'],
    ASSESSMENT_ASSIGNED: ['ASSESSMENT_ASSIGNMENT'],
    // ASSESSMENT_CLOSED: ['ASSESSMENT'],
    // ASSESSMENT_DUE: ['ASSESSMENT'],
    // ASSIGNED_ASSESSMENT_COMPLETE: ['ASSESSMENT_ASSIGNMENT'],
    DOCUMENT_CHANGE: ['PRODUCT', 'VENDOR'],
    DSAR_NEW_REQUEST_SUMMRY: ['DSAR'],
    DSAR_DEADLINE_SUMMARY_V2: ['DSAR'],
    GDPR_REQUEST: ['GDPR'],
    LEGISLATION_POST_DELETE: ['LEGISLATION'],
    LEGISLATION_POST_UPDATE: ['LEGISLATION'],
    LITIGATION_UPDATE: ['VENDOR', 'LITIGATION'],
    SCAN: ['DISCO'],
    SCORE_UPDATE: ['PRODUCT', 'VENDOR'],
    TATTLE: ['CONSENTMANAGER'],
    VENDOR_BREACH: ['VENDOR'],
    REVIEW_DATE: ['VENDOR'],
};

export const RESOURCE_EVENT_MAP = {
    ASKANALYST: ['ASKANALYST_RESPONSE'],
    // ASSESSMENT: ['ASSESSMENT_CLOSED', 'ASSESSMENT_DUE'],
    ASSESSMENT_ASSIGNMENT: ['ASSESSMENT_ASSIGNED' /*, 'ASSIGNED_ASSESSMENT_COMPLETE'*/],
    CONSENTMANAGER: ['TATTLE'],
    DISCO: ['SCAN'],
    DSAR: ['DSAR_NEW_REQUEST_SUMMRY', 'DSAR_REQUEST_DEADLINE_SUMMRY', 'DSAR_DEADLINE_SUMMARY_V2'],
    GDPR: ['GDPR_REQUEST'],
    LEGISLATION: ['LEGISLATION_POST_UPDATE', 'LEGISLATION_POST_DELETE'],
    LITIGATION: ['LITIGATION_UPDATE'],
    PRODUCT: ['SCORE_UPDATE', 'DOCUMENT_CHANGE'],
    VENDOR: ['SCORE_UPDATE', 'DOCUMENT_CHANGE', 'LITIGATION_UPDATE', 'REVIEW_DATE'],
    SITE_BREACH: ['VENDOR_BREACH'],
};

export const URGENT_TYPES = [
    // EVENT_TYPE.ASSESSMENT_DUE,
    EVENT_TYPE.DSAR_NEW_REQUEST_SUMMRY,
];

export const NOTIFICATION_TYPES = {
    CONSENT_MANAGER: 'consent_manager_notifications',
    DATA_MAPPING: 'data_mapping_notifications',
    DSAR: 'dsar_notifications',
    LEGISLATION: 'legislation_notifications',
    LITIGATION: 'litigation_notifications',
    VENDOR: 'vendor_notifications',
    ASSESSMENT: 'assessment_notifications',
    SITE_BREACH: 'site_breach_notifications',
};

const icons = {
    default: {
        icon: DefaultIcon,
        color: 'red',
    },
    ask: {
        icon: AskExpertIcon,
        active: true,
    },
    assessment: {
        icon: AssessmentIcon,
        active: true,
    },
    consent: {
        icon: ConsentIcon,
        active: true,
    },
    document: {
        icon: DocumentIcon,
        active: true,
    },
    dsar: {
        icon: DSARIcon,
        active: true,
    },
    gdpr: {
        icon: GDPRIcon,
        active: true,
    },
    legislative: {
        icon: LegislationIcon,
        active: true,
    },
    litigation: {
        icon: LitigationIcon,
        active: true,
    },
    product: {
        icon: ProductIcon,
        active: true,
    },
    score: {
        icon: ScoreIcon,
        active: true,
    },
    vendor: {
        icon: VendorIcon,
        active: true,
    },
};

export const NOTIFICATION_ICONS = {
    default: icons.default,
    [RESOURCE_TYPE.ASKANALYST]: icons.ask,
    // [RESOURCE_TYPE.ASSESSMENT]: icons.assessment,
    [RESOURCE_TYPE.ASSESSMENT_ASSIGNMENT]: icons.assessment,
    [RESOURCE_TYPE.CONSENTMANAGER]: icons.consent,
    [RESOURCE_TYPE.DSAR]: icons.dsar,
    [RESOURCE_TYPE.GDPR]: icons.gdpr,
    [RESOURCE_TYPE.LEGISLATION]: icons.legislative,
    [RESOURCE_TYPE.LITIGATION]: icons.litigation,
    [RESOURCE_TYPE.PRODUCT]: icons.product,
    [RESOURCE_TYPE.VENDOR]: icons.vendor,
    [EVENT_TYPE.ASKANALYST_RESPONSE]: icons.ask,
    [EVENT_TYPE.ASSESSMENT_ASSIGNED]: icons.assessment,
    // [EVENT_TYPE.ASSESSMENT_CLOSED]: icons.assessment,
    // [EVENT_TYPE.ASSESSMENT_DUE]: icons.assessment,
    // [EVENT_TYPE.ASSIGNED_ASSESSMENT_COMPLETE]: icons.assessment,
    [EVENT_TYPE.DOCUMENT_CHANGE]: icons.document,
    [EVENT_TYPE.DSAR_NEW_REQUEST_SUMMRY]: icons.dsar,
    [EVENT_TYPE.DSAR_REQUEST_NEW_PORTAL_MESSAGE]: icons.dsar,
    [EVENT_TYPE.DSAR_DEADLINE_SUMMARY_V2]: icons.dsar,
    [EVENT_TYPE.DSAR_REQUEST_DEADLINE_SUMMRY]: icons.dsar,
    [EVENT_TYPE.GDPR_REQUEST]: icons.gdpr,
    [EVENT_TYPE.LEGISLATION_POST_DELETE]: icons.legislative,
    [EVENT_TYPE.LEGISLATION_POST_UPDATE]: icons.legislative,
    [EVENT_TYPE.LITIGATION_UPDATE]: icons.litigation,
    [EVENT_TYPE.SCORE_UPDATE]: icons.score,
    [EVENT_TYPE.TATTLE]: icons.consent,
};
